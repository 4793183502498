.nav-container {
  position: fixed;
  width: 100%;
  z-index: 5;
  padding: 0 56px;
}
@media (max-width: 768px) {
  .nav-container {
    padding: 0 !important;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .nav-container .button-container {
    display: none;
  }
}
.nav-container .menu-container {
  display: none;
  padding: 1rem 1.5rem;
  background-color: #f27c38;
  width: 100%;
  z-index: 2;
}
.nav-container .menu-container .menu-item {
  margin-top: 1rem;
  font-size: 1.1rem;
}
@media (max-width: 768px) {
  .nav-container .menu-container {
    display: block;
  }
}
@media (min-width: 769px) {
  .nav-container .menu-container {
    display: none;
  }
}
.nav-container .menu-container img {
  float: right;
}
@media (max-width: 768px) {
  .nav-container .menu-container img {
    display: block;
  }
}
@media (min-width: 769px) {
  .nav-container .menu-container img {
    display: none;
  }
}
.nav-container .menu-button {
  background-color: transparent;
  outline: none;
  border: none;
  margin-right: 0.5rem;
}
.nav-container .menu-button img {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  margin-top: 1rem;
}
@media (max-width: 768px) {
  .desktop_carousel {
    display: none;
  }
}
.tablet_carousel {
  display: none;
}
@media (min-width: 426px) and (max-width: 768px) {
  .tablet_carousel {
    display: block;
  }
}
@media (max-width: 426px) {
  .mobile_carousel {
    display: block;
  }
}
@media (min-width: 427px) {
  .mobile_carousel {
    display: none;
  }
}
