.carousel-root {
  height: 100vh;
}
.hero-container {
  height: 750px;
  background-image: url("../assets/images/hero-background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #2a2a2c;
  position: relative;
}
@media (max-width: 426px) {
  .hero-container {
    height: 100vh;
  }
}
.hero-container .hero-section-flex-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.hero-container .home-title {
  font-size: 10rem;
  color: #f8d193;
  margin-bottom: -1.5rem;
}
@media (max-width: 1024px) {
  .hero-container .home-title {
    font-size: 6rem;
    margin-bottom: -1rem;
  }
}
@media (max-width: 768px) {
  .hero-container .home-title {
    font-size: 4rem;
    margin-bottom: -0.7rem;
  }
}
@media (max-width: 426px) {
  .hero-container .home-title {
    font-size: 2.5rem;
    margin-bottom: 0.2rem;
  }
}
@media (max-width: 1024px) {
  .hero-container .tagline {
    font-size: 2rem;
  }
}
@media (max-width: 768px) {
  .hero-container .tagline {
    font-size: 1.5rem;
  }
}
@media (max-width: 426px) {
  .hero-container .tagline {
    font-size: 1rem;
  }
}
@media (max-width: 426px) {
  .hero-container .date-time {
    font-size: 0.8rem;
  }
}
.second-section-container {
  padding: 6rem 9rem;
}
@media (max-width: 426px) {
  .second-section-container {
    padding: 7rem 2rem;
  }
}
@media (max-width: 768px) {
  .second-section-container .second-section {
    flex-direction: column;
  }
}
.second-section-container .second-section-heading {
  font-size: 3rem;
}
@media (max-width: 426px) {
  .second-section-container .second-section-heading {
    font-size: 2rem;
    font-weight: 800;
  }
}
.second-section-container .event-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 2rem 0;
  padding: 1rem 1.5rem;
  width: 70%;
}
.second-section-container .event-container .event-name {
  flex: 3;
}
.second-section-container .event-container .event-time {
  flex: 1;
}
@media (max-width: 1024px) {
  .second-section-container .event-container {
    width: 100%;
  }
  .second-section-container .event-container .event-name {
    flex: 2;
  }
  .second-section-container .event-container .event-time {
    flex: 1;
  }
}
@media (max-width: 768px) {
  .second-section-container .event-container .event-name {
    flex: 1;
  }
  .second-section-container .event-container .event-time {
    flex: 1;
  }
}
@media (max-width: 426px) {
  .second-section-container .event-container {
    padding: 0.2rem 0 0.2rem 0.5rem;
  }
  .second-section-container .event-container .event-name {
    flex: 1;
  }
  .second-section-container .event-container .event-time {
    flex: 1.5;
  }
}
.timeline-section-container {
  background-color: #f2594b;
  padding: 6rem 9rem;
}
@media (max-width: 426px) {
  .timeline-section-container {
    padding: 7rem 2rem;
  }
}
@media (max-width: 768px) {
  .timeline-section-container #timeline-heading {
    font-size: 2.5rem;
  }
}
@media (max-width: 426px) {
  .timeline-section-container #timeline-heading {
    margin-bottom: 1rem;
    font-size: 2rem;
  }
}
.timeline-section-container img {
  margin-top: 3rem;
  width: 85%;
}
@media (max-width: 426px) {
  .timeline-section-container img {
    width: 100%;
  }
}
.third-section-container {
  background-color: #4286f5;
  padding: 6rem 9rem;
}
@media (max-width: 426px) {
  .third-section-container {
    padding: 7rem 2rem;
  }
}
@media (max-width: 1024px) {
  .third-section-container .third-section-flex {
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .third-section-container .third-section-flex {
    flex-direction: column;
  }
}
@media (max-width: 1024px) {
  .third-section-container .left {
    flex: 1;
  }
  .third-section-container .left p {
    width: 80%;
  }
  .third-section-container .left img {
    width: 15rem;
  }
}
@media (max-width: 426px) {
  .third-section-container .left p {
    width: 100%;
  }
  .third-section-container .left img {
    width: 15rem;
  }
}
@media (max-width: 1024px) {
  .third-section-container .right {
    flex: 1;
  }
  .third-section-container .right p {
    width: 80%;
  }
  .third-section-container .right img {
    width: 15rem;
  }
}
@media (max-width: 426px) {
  .third-section-container .right p {
    width: 100%;
  }
  .third-section-container .right img {
    width: 15rem;
  }
}
@media (max-width: 1024px) {
  .third-section-container .theme_tagline {
    width: 60%;
  }
}
@media (max-width: 768px) {
  .third-section-container .theme_tagline {
    width: 90%;
  }
}
@media (max-width: 1024px) {
  .third-section-container .theme_heading {
    font-size: 48px;
  }
}
@media (max-width: 426px) {
  .third-section-container .theme_heading {
    font-size: 2rem;
  }
}
.fourth-section-container {
  padding: 6rem 9rem;
}
@media (max-width: 426px) {
  .fourth-section-container {
    padding: 7rem 2rem;
  }
}
@media (max-width: 426px) {
  .fourth-section-container .fourth-section-heading {
    font-size: 32px !important;
  }
}
@media (max-width: 1024px) {
  .fourth-section-container .fourth-section-heading {
    font-size: 48px;
  }
}
@media (max-width: 1024px) {
  .fourth-section-container .sub-section-heading {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .fourth-section-container .fourth-section-reverse-flex-container {
    flex-direction: column-reverse;
    align-items: start;
  }
}
@media (max-width: 426px) {
  .fourth-section-container .fourth-section-reverse-flex-container {
    flex-direction: column-reverse;
    align-items: center;
  }
  .fourth-section-container .fourth-section-reverse-flex-container div img {
    width: 15rem;
  }
  .fourth-section-container .fourth-section-reverse-flex-container div ul {
    width: 100%;
  }
  .fourth-section-container .fourth-section-reverse-flex-container div h2 {
    font-size: 1.5rem;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .fourth-section-container .fourth-section-flex-container {
    flex-direction: column;
    align-items: start;
  }
}
@media (max-width: 426px) {
  .fourth-section-container .fourth-section-flex-container {
    flex-direction: column;
    align-items: center;
  }
  .fourth-section-container .fourth-section-flex-container div img {
    width: 15rem;
  }
  .fourth-section-container .fourth-section-flex-container div ul {
    margin-top: 1rem;
    width: 100%;
  }
  .fourth-section-container .fourth-section-flex-container div h2 {
    font-size: 1.5rem;
    width: 100%;
  }
}
.fifth-section-container {
  background-color: #eaeaee;
  padding: 6rem 9rem;
}
.fifth-section-container .fifth-flex-container {
  align-items: flex-start;
}
@media (max-width: 768px) {
  .fifth-section-container .fifth-flex-container {
    align-items: center;
    text-align: center;
  }
}
@media (max-width: 426px) {
  .fifth-section-container {
    padding: 7rem 2rem;
  }
}
@media (max-width: 768px) {
  .fifth-section-container .fifth-section-heading {
    font-size: 3.5rem;
  }
}
@media (max-width: 426px) {
  .fifth-section-container .fifth-section-heading {
    font-size: 2rem;
  }
}
.fifth-section-container .shortly-msg {
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
}
@media (max-width: 768px) {
  .fifth-section-container .shortly-msg {
    font-size: 1.5rem;
    text-align: left;
  }
}
@media (max-width: 426px) {
  .fifth-section-container .shortly-msg {
    text-align: center;
  }
}
.fifth-section-container .judge-container {
  min-width: 30%;
  height: max-content;
  padding: 2rem 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.fifth-section-container .judge-container .judge-img-container {
  width: 18rem;
  height: 18rem;
  margin: 0 auto;
  border-radius: 50%;
}
.fifth-section-container .judge-container .judge-img-container img {
  width: 18rem;
  height: 18rem;
  object-fit: cover;
}
.fifth-section-container .judge-container h3 {
  margin-top: 1rem;
  font-weight: 600;
}
.fifth-section-container .judge-container p {
  color: #646567;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}
.sixth-section-container {
  background-color: #ff6161;
  padding: 6rem 9rem;
}
.sixth-section-container .sixth-flex-container {
  align-items: flex-start;
}
@media (max-width: 768px) {
  .sixth-section-container .sixth-flex-container {
    align-items: center;
    text-align: center;
  }
}
@media (max-width: 426px) {
  .sixth-section-container {
    padding: 7rem 2rem;
  }
}
@media (max-width: 768px) {
  .sixth-section-container .sixth-section-heading {
    font-size: 3.5rem;
  }
}
@media (max-width: 426px) {
  .sixth-section-container .sixth-section-heading {
    font-size: 2rem;
  }
}
.sixth-section-container .prize-flex-container {
  margin: 0 auto;
  width: 80%;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .sixth-section-container .prize-flex-container {
    width: 100%;
    flex-wrap: wrap;
    gap: 2;
  }
}
@media (max-width: 768px) {
  .sixth-section-container .prize-flex-container {
    display: none;
  }
}
.sixth-section-container .prize-flex-container .card {
  background-color: white;
  border-radius: 16px;
}
.sixth-section-container .prize-flex-container .card p {
  margin-top: 2rem;
  color: #646567;
  font-size: 0.85rem;
}
.sixth-section-container .prize-flex-container .card h3 {
  margin-top: 0.5rem;
  font-size: 1.5rem;
}
.sixth-section-container .prize-flex-container .big-card {
  background-color: white;
  border-radius: 16px;
  padding: 5rem 6rem;
}
.sixth-section-container .prize-flex-container .big-card p {
  margin-top: 2rem;
  color: #646567;
  font-size: 0.85rem;
}
.sixth-section-container .prize-flex-container .big-card h3 {
  margin-top: 0.5rem;
  font-size: 1.5rem;
}
.sixth-section-container .prize-flex-container .big-card img {
  width: 8rem;
}
@media (max-width: 1024px) {
  .sixth-section-container .prize-flex-container .big-card {
    padding: 3rem 3rem;
  }
  .sixth-section-container .prize-flex-container .big-card img {
    width: 6rem;
  }
}
.sixth-section-container .prize-flex-container .medium-card {
  background-color: white;
  border-radius: 16px;
  padding: 4rem 5rem;
}
.sixth-section-container .prize-flex-container .medium-card p {
  margin-top: 2rem;
  color: #646567;
  font-size: 0.85rem;
}
.sixth-section-container .prize-flex-container .medium-card h3 {
  margin-top: 0.5rem;
  font-size: 1.5rem;
}
.sixth-section-container .prize-flex-container .medium-card img {
  width: 7rem;
}
@media (max-width: 1024px) {
  .sixth-section-container .prize-flex-container .medium-card {
    padding: 3rem 3rem;
  }
  .sixth-section-container .prize-flex-container .medium-card img {
    width: 6rem;
  }
}
.sixth-section-container .prize-flex-container .small-card {
  background-color: white;
  border-radius: 16px;
  padding: 3rem 3rem;
}
.sixth-section-container .prize-flex-container .small-card p {
  margin-top: 2rem;
  color: #646567;
  font-size: 0.85rem;
}
.sixth-section-container .prize-flex-container .small-card h3 {
  margin-top: 0.5rem;
  font-size: 1.5rem;
}
.sixth-section-container .prize-flex-container .small-card img {
  width: 6rem;
}
@media (max-width: 1024px) {
  .sixth-section-container .prize-flex-container .small-card {
    padding: 3rem 3rem;
  }
  .sixth-section-container .prize-flex-container .small-card img {
    width: 6rem;
  }
}
@media (min-width: 769px) {
  .sixth-section-container .mobile-card-container {
    display: none;
  }
}
.sixth-section-container .mobile-card-container .card {
  background-color: white;
  border-radius: 16px;
  width: 16rem;
  margin: 0 auto;
  margin-bottom: 3rem;
  text-align: center;
  padding: 3rem 3rem;
}
.sixth-section-container .mobile-card-container .card p {
  margin-top: 2rem;
  color: #646567;
  font-size: 0.85rem;
}
.sixth-section-container .mobile-card-container .card h3 {
  margin-top: 0.5rem;
  font-size: 1.5rem;
}
.sixth-section-container .mobile-card-container .card img {
  width: 6rem;
}
.line {
  width: 0.2rem;
  height: 3rem;
  background-color: #fe7601;
  margin-right: 1rem;
}
