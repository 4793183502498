.problem-container {
  height: 100vh;
  background: linear-gradient(#f08f57, white);
}
.problem-container .problem-statement-flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 5rem;
  /* Accordion styles */
}
.problem-container .problem-statement-flex-container .note {
  font-size: 0.8rem;
  margin: 2rem 0;
}
.problem-container .problem-statement-flex-container h1 {
  margin: 0;
  line-height: 2;
  text-align: center;
}
@media (max-width: 768px) {
  .problem-container .problem-statement-flex-container h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
  }
}
@media (max-width: 426px) {
  .problem-container .problem-statement-flex-container h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}
.problem-container .problem-statement-flex-container h2 {
  margin: 0 0 0.5em;
  font-weight: normal;
}
.problem-container .problem-statement-flex-container input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.problem-container .problem-statement-flex-container .row {
  display: flex;
  width: 60%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .problem-container .problem-statement-flex-container .row {
    width: 80%;
  }
}
.problem-container .problem-statement-flex-container .row .col {
  flex: 1;
}
.problem-container .problem-statement-flex-container .tabs {
  overflow: hidden;
}
.problem-container .problem-statement-flex-container .tab {
  border-radius: 8px;
  margin-top: 1rem;
  width: 100%;
  color: black;
  overflow: hidden;
  border: #d0d3ff solid 1px;
}
.problem-container .problem-statement-flex-container .tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: white;
  opacity: 0.8;
  font-weight: 600;
  cursor: pointer;
  /* Icon */
}
.problem-container .problem-statement-flex-container .tab-label:hover {
  background: #fafafa;
}
.problem-container .problem-statement-flex-container .tab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.05s;
}
.problem-container .problem-statement-flex-container .tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #767b82;
  background: white;
  opacity: 0.9;
  text-align: justify;
  transition: all 0.05s;
  font-size: 0.9rem;
}
.problem-container .problem-statement-flex-container .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: white;
  cursor: pointer;
}
.problem-container .problem-statement-flex-container .tab-close:hover {
  background: #fafafa;
}
.problem-container .problem-statement-flex-container input:checked + .tab-label {
  background: #fafafa;
}
.problem-container .problem-statement-flex-container input:checked + .tab-label::after {
  transform: rotate(90deg);
}
.problem-container .problem-statement-flex-container input:checked ~ .tab-content {
  max-height: max-content;
  padding: 2rem 4rem;
  line-height: 1.8rem;
}
.problem-container .problem-statement-flex-container .content-tab-heading {
  font-weight: 600;
  text-align: left;
  font-size: 1rem;
  color: #0c1d37;
}
.problem-container .problem-statement-flex-container .content-problem-statement {
  font-size: 500;
  color: #646567;
}
.problem-container .problem-statement-flex-container .content-motivation-list li {
  list-style: none;
}
.problem-container .problem-statement-flex-container .content-motivation-list li:before {
  content: "• ";
  color: #fe7601;
}
.problem-container .problem-statement-flex-container .img-container {
  background-color: #fe7601;
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}
.problem-container .problem-statement-flex-container .img-container img {
  width: 1.2rem;
}
.problem-container .problem-statement-flex-container .info-container {
  width: 60%;
  margin: 0 auto 2rem auto;
  background-color: #fff;
  padding: 2rem 4rem;
  border-radius: 8px;
  display: flex;
  border: #d0d3ff solid 1px;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 1024px) {
  .problem-container .problem-statement-flex-container .info-container {
    width: 80%;
  }
}
.problem-container .problem-statement-flex-container .info-container h2 {
  text-align: left;
  font-size: 1.2rem;
  font-weight: 600;
}
.problem-container .problem-statement-flex-container .info-container ul {
  margin-bottom: 2rem;
  margin-left: 1rem;
}
.problem-container .problem-statement-flex-container .info-container ul li {
  line-height: 1.8rem;
  font-size: 0.9rem;
  letter-spacing: 0.015rem;
}
.problem-container .problem-statement-flex-container .info-container ul li a,
.problem-container .problem-statement-flex-container .info-container ul li a:visited,
.problem-container .problem-statement-flex-container .info-container ul li a:active {
  color: black;
  text-decoration: none;
  font-style: italic;
  letter-spacing: 0.05rem;
}
.problem-container .problem-statement-flex-container .info-container article {
  line-height: 1.8rem;
  font-size: 0.9rem;
  letter-spacing: 0.015rem;
}
.problem-container .problem-statement-flex-container .info-container article b {
  font-weight: 600;
}
.problem-container .problem-statement-flex-container .info-container .end-note {
  margin-top: 2rem;
  font-size: 0.9rem;
  font-style: italic;
}
.problem-container .problem-statement-flex-container .info-container .end-note b {
  font-weight: 600;
}
.problem-container .problem-statement-flex-container .build-for {
  width: 15rem;
}
@media (max-width: 426px) {
  .problem-container .problem-statement-flex-container .build-for {
    width: 12rem;
  }
}
@media (max-width: 326px) {
  .problem-container .problem-statement-flex-container .build-for {
    width: 8rem;
  }
}
