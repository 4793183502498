.faq-container {
  background: linear-gradient(#c5c8fc, #fff);
  /* Accordion styles */
}
.faq-container .faq-heading {
  font-weight: 700;
  font-size: 4rem;
  font-style: italic;
  line-height: 5rem;
  margin: 0 auto;
  padding-top: 6rem;
  margin-bottom: 1rem;
}
@media (max-width: 1024px) {
  .faq-container .faq-heading {
    font-size: 3rem;
  }
}
@media (max-width: 768px) {
  .faq-container .faq-heading {
    font-size: 2.5rem;
  }
}
@media (max-width: 426px) {
  .faq-container .faq-heading {
    font-size: 2rem;
    width: 80%;
    line-height: 3rem;
    margin-top: 5rem;
  }
}
@media (max-width: 376px) {
  .faq-container .faq-heading {
    font-size: 1.5rem;
  }
}
.faq-container h1 {
  margin: 0;
  line-height: 2;
  text-align: center;
}
.faq-container h2 {
  margin: 0 0 0.5em;
  font-weight: normal;
}
.faq-container input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.faq-container .row {
  display: flex;
  width: 60%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .faq-container .row {
    width: 80%;
  }
}
.faq-container .row .col {
  flex: 1;
}
.faq-container .row .col:last-child {
  margin-left: 1em;
}
.faq-container .tabs {
  overflow: hidden;
}
.faq-container .tab {
  border-radius: 8px;
  margin-top: 1rem;
  width: 100%;
  color: black;
  overflow: hidden;
  border: #d0d3ff solid 1px;
}
.faq-container .tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: white;
  opacity: 0.8;
  font-weight: 600;
  cursor: pointer;
  /* Icon */
}
.faq-container .tab-label:hover {
  background: #fafafa;
}
.faq-container .tab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
.faq-container .tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #767b82;
  background: white;
  opacity: 0.9;
  text-align: justify;
  transition: all 0.35s;
  font-size: 0.9rem;
}
.faq-container .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: white;
  cursor: pointer;
}
.faq-container .tab-close:hover {
  background: #fafafa;
}
.faq-container input:checked + .tab-label {
  background: #fafafa;
}
.faq-container input:checked + .tab-label::after {
  transform: rotate(90deg);
}
.faq-container input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}
