.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-direction-col {
  flex-direction: column;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-1-2 {
  flex: 1.2;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-start {
  justify-content: start;
}
.justify-end {
  justify-content: end;
}
.center-element {
  display: flex;
  align-items: center;
  justify-content: center;
}
.m-4 {
  margin: 0.25rem;
}
.m-8 {
  margin: 0.5rem;
}
.m-12 {
  margin: 0.75rem;
}
.m-16 {
  margin: 1rem;
}
.m-20 {
  margin: 1.25rem;
}
.m-24 {
  margin: 1.5rem;
}
.m-28 {
  margin: 1.75rem;
}
.m-32 {
  margin: 2rem;
}
.m-36 {
  margin: 2.25rem;
}
.m-40 {
  margin: 2.5rem;
}
.m-44 {
  margin: 2.75rem;
}
.m-48 {
  margin: 3rem;
}
.mt-4 {
  margin-top: 0.25rem;
}
.mt-8 {
  margin-top: 0.5rem;
}
.mt-12 {
  margin-top: 0.75rem;
}
.mt-16 {
  margin-top: 1rem;
}
.mt-20 {
  margin-top: 1.25rem;
}
.mt-24 {
  margin-top: 1.5rem;
}
.mt-28 {
  margin-top: 1.75rem;
}
.mt-32 {
  margin-top: 2rem;
}
.mt-36 {
  margin-top: 2.25rem;
}
.mt-40 {
  margin-top: 2.5rem;
}
.mt-44 {
  margin-top: 2.75rem;
}
.mt-48 {
  margin-top: 3rem;
}
.mt-96 {
  margin-top: 6rem;
}
.mb-4 {
  margin-bottom: 0.25rem;
}
.mb-8 {
  margin-bottom: 0.5rem;
}
.mb-12 {
  margin-bottom: 0.75rem;
}
.mb-16 {
  margin-bottom: 1rem;
}
.mb-20 {
  margin-bottom: 1.25rem;
}
.mb-24 {
  margin-bottom: 1.5rem;
}
.mb-28 {
  margin-bottom: 1.75rem;
}
.mb-32 {
  margin-bottom: 2rem;
}
.mb-36 {
  margin-bottom: 2.25rem;
}
.mb-40 {
  margin-bottom: 2.5rem;
}
.mb-44 {
  margin-bottom: 2.75rem;
}
.mb-48 {
  margin-bottom: 3rem;
}
.ml-4 {
  margin-left: 0.25rem;
}
.ml-8 {
  margin-left: 0.5rem;
}
.ml-12 {
  margin-left: 0.75rem;
}
.ml-16 {
  margin-left: 1rem;
}
.ml-20 {
  margin-left: 1.25rem;
}
.ml-24 {
  margin-left: 1.5rem;
}
.ml-28 {
  margin-left: 1.75rem;
}
.ml-32 {
  margin-left: 2rem;
}
.ml-36 {
  margin-left: 2.25rem;
}
.ml-40 {
  margin-left: 2.5rem;
}
.ml-44 {
  margin-left: 2.75rem;
}
.ml-48 {
  margin-left: 3rem;
}
.mr-4 {
  margin-right: 0.25rem;
}
.mr-8 {
  margin-right: 0.5rem;
}
.mr-12 {
  margin-right: 0.75rem;
}
.mr-16 {
  margin-right: 1rem;
}
.mr-20 {
  margin-right: 1.25rem;
}
.mr-24 {
  margin-right: 1.5rem;
}
.mr-28 {
  margin-right: 1.75rem;
}
.mr-32 {
  margin-right: 2rem;
}
.mr-36 {
  margin-right: 2.25rem;
}
.mr-40 {
  margin-right: 2.5rem;
}
.mr-44 {
  margin-right: 2.75rem;
}
.mr-48 {
  margin-right: 3rem;
}
.mx-auto {
  margin: 0 auto;
}
.mx-4 {
  margin: 0 0.25rem;
}
.mx-8 {
  margin: 0 0.5rem;
}
.mx-12 {
  margin: 0 0.75rem;
}
.mx-16 {
  margin: 0 1rem;
}
.mx-20 {
  margin: 0 1.25rem;
}
.mx-24 {
  margin: 0 1.5rem;
}
.mx-28 {
  margin: 0 1.75rem;
}
.mx-32 {
  margin: 0 2rem;
}
.mx-36 {
  margin: 0 2.25rem;
}
.mx-40 {
  margin: 0 2.5rem;
}
.mx-44 {
  margin: 0 2.75rem;
}
.mx-48 {
  margin: 0 3rem;
}
.my-4 {
  margin: 0.25rem 0;
}
.my-8 {
  margin: 0.5rem 0;
}
.my-12 {
  margin: 0.75rem 0;
}
.my-16 {
  margin: 1rem 0;
}
.my-20 {
  margin: 1.25rem 0;
}
.my-24 {
  margin: 1.5rem 0;
}
.my-28 {
  margin: 1.75rem 0;
}
.my-32 {
  margin: 2rem 0;
}
.my-36 {
  margin: 2.25rem 0;
}
.my-40 {
  margin: 2.5rem 0;
}
.my-44 {
  margin: 2.75rem 0;
}
.my-48 {
  margin: 3rem 0;
}
.p-4 {
  padding: 0.25rem;
}
.p-8 {
  padding: 0.5rem;
}
.p-12 {
  padding: 0.75rem;
}
.p-16 {
  padding: 1rem;
}
.p-20 {
  padding: 1.25rem;
}
.p-24 {
  padding: 1.5rem;
}
.p-28 {
  padding: 1.75rem;
}
.p-32 {
  padding: 2rem;
}
.p-36 {
  padding: 2.25rem;
}
.p-40 {
  padding: 2.5rem;
}
.p-44 {
  padding: 2.75rem;
}
.p-48 {
  padding: 3rem;
}
.pt-4 {
  padding-top: 0.25rem;
}
.pt-8 {
  padding-top: 0.5rem;
}
.pt-12 {
  padding-top: 0.75rem;
}
.pt-16 {
  padding-top: 1rem;
}
.pt-20 {
  padding-top: 1.25rem;
}
.pt-24 {
  padding-top: 1.5rem;
}
.pt-28 {
  padding-top: 1.75rem;
}
.pt-32 {
  padding-top: 2rem;
}
.pt-36 {
  padding-top: 2.25rem;
}
.pt-40 {
  padding-top: 2.5rem;
}
.pt-44 {
  padding-top: 2.75rem;
}
.pt-48 {
  padding-top: 3rem;
}
.pb-4 {
  padding-bottom: 0.25rem;
}
.pb-8 {
  padding-bottom: 0.5rem;
}
.pb-12 {
  padding-bottom: 0.75rem;
}
.pb-16 {
  padding-bottom: 1rem;
}
.pb-20 {
  padding-bottom: 1.25rem;
}
.pb-24 {
  padding-bottom: 1.5rem;
}
.pb-28 {
  padding-bottom: 1.75rem;
}
.pb-32 {
  padding-bottom: 2rem;
}
.pb-36 {
  padding-bottom: 2.25rem;
}
.pb-40 {
  padding-bottom: 2.5rem;
}
.pb-44 {
  padding-bottom: 2.75rem;
}
.pb-48 {
  padding-bottom: 3rem;
}
.pl-4 {
  padding-left: 0.25rem;
}
.pl-8 {
  padding-left: 0.5rem;
}
.pl-12 {
  padding-left: 0.75rem;
}
.pl-16 {
  padding-left: 1rem;
}
.pl-20 {
  padding-left: 1.25rem;
}
.pl-24 {
  padding-left: 1.5rem;
}
.pl-28 {
  padding-left: 1.75rem;
}
.pl-32 {
  padding-left: 2rem;
}
.pl-36 {
  padding-left: 2.25rem;
}
.pl-40 {
  padding-left: 2.5rem;
}
.pl-44 {
  padding-left: 2.75rem;
}
.pl-48 {
  padding-left: 3rem;
}
.pr-4 {
  padding-right: 0.25rem;
}
.pr-8 {
  padding-right: 0.5rem;
}
.pr-12 {
  padding-right: 0.75rem;
}
.pr-16 {
  padding-right: 1rem;
}
.pr-20 {
  padding-right: 1.25rem;
}
.pr-24 {
  padding-right: 1.5rem;
}
.pr-28 {
  padding-right: 1.75rem;
}
.pr-32 {
  padding-right: 2rem;
}
.pr-36 {
  padding-right: 2.25rem;
}
.pr-40 {
  padding-right: 2.5rem;
}
.pr-44 {
  padding-right: 2.75rem;
}
.pr-48 {
  padding-right: 3rem;
}
.px-4 {
  padding: 0 0.25rem;
}
.px-8 {
  padding: 0 0.5rem;
}
.px-12 {
  padding: 0 0.75rem;
}
.px-16 {
  padding: 0 1rem;
}
.px-20 {
  padding: 0 1.25rem;
}
.px-24 {
  padding: 0 1.5rem;
}
.px-28 {
  padding: 0 1.75rem;
}
.px-32 {
  padding: 0 2rem;
}
.px-36 {
  padding: 0 2.25rem;
}
.px-40 {
  padding: 0 2.5rem;
}
.px-44 {
  padding: 0 2.75rem;
}
.px-48 {
  padding: 0 3rem;
}
.px-96 {
  padding: 0 6rem;
}
.py-4 {
  padding: 0.25rem 0;
}
.py-8 {
  padding: 0.5rem 0;
}
.py-12 {
  padding: 0.75rem 0;
}
.py-16 {
  padding: 1rem 0;
}
.py-20 {
  padding: 1.25rem 0;
}
.py-24 {
  padding: 1.5rem 0;
}
.py-28 {
  padding: 1.75rem 0;
}
.py-32 {
  padding: 2rem 0;
}
.py-36 {
  padding: 2.25rem 0;
}
.py-40 {
  padding: 2.5rem 0;
}
.py-44 {
  padding: 2.75rem 0;
}
.py-48 {
  padding: 3rem 0;
}
.text-white {
  color: white;
}
.text-grey-500 {
  color: #cdcdce;
}
.text-grey-700 {
  color: #646567;
}
.text-yellow {
  color: #e0a503;
}
.bg-black {
  background-color: #000;
}
.bg-orange {
  background-color: #f7833b;
}
.bg-transparent {
  background-color: transparent;
}
.italic {
  font-style: italic;
}
.font-4 {
  font-size: 0.25rem;
}
.font-8 {
  font-size: 0.5rem;
}
.font-12 {
  font-size: 0.75rem;
}
.font-14 {
  font-size: 0.825rem;
}
.font-16 {
  font-size: 1rem;
}
.font-18 {
  font-size: 1.15rem;
}
.font-20 {
  font-size: 1.25rem;
}
.font-24 {
  font-size: 1.5rem;
}
.font-28 {
  font-size: 1.75rem;
}
.font-32 {
  font-size: 2rem;
}
.font-36 {
  font-size: 2.25rem;
}
.font-40 {
  font-size: 2.5rem;
}
.font-44 {
  font-size: 2.75rem;
}
.font-48 {
  font-size: 3rem;
}
.font-52 {
  font-size: 3.25rem;
}
.font-56 {
  font-size: 3.5rem;
}
.font-60 {
  font-size: 3.75rem;
}
.font-64 {
  font-size: 4rem;
}
.font-68 {
  font-size: 4.25rem;
}
.font-72 {
  font-size: 4.5rem;
}
.font-76 {
  font-size: 4.75rem;
}
.font-80 {
  font-size: 5rem;
}
.font-84 {
  font-size: 5.25rem;
}
.font-88 {
  font-size: 5.5rem;
}
.font-92 {
  font-size: 5.75rem;
}
.font-96 {
  font-size: 6rem;
}
.weight-100 {
  font-weight: 100;
}
.weight-200 {
  font-weight: 200;
}
.weight-300 {
  font-weight: 300;
}
.weight-400 {
  font-weight: 400;
}
.weight-500 {
  font-weight: 500;
}
.weight-600 {
  font-weight: 600;
}
.weight-700 {
  font-weight: 700;
}
.weight-800 {
  font-weight: 800;
}
.weight-900 {
  font-weight: 900;
}
.line-height-1_8 {
  line-height: 1.8rem;
}
.width-30 {
  width: 30%;
}
.width-40 {
  width: 40%;
}
.width-60 {
  width: 60%;
}
.width-80 {
  width: 80%;
}
.width-23 {
  width: 23rem;
}
.width-30r {
  width: 23rem;
}
.width-10 {
  width: 10rem;
}
.width-full {
  width: 100%;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.link,
.link:active,
.link:visited {
  color: white;
  text-decoration: none;
}
