.footer-container .footer-flex-container {
  padding: 0 10rem;
  margin-top: 10rem;
  margin-bottom: 4rem;
}
@media (max-width: 768px) {
  .footer-container .footer-flex-container {
    padding: 0 2rem;
  }
}
@media (max-width: 426px) {
  .footer-container .footer-flex-container {
    padding: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.footer-container .mool {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.footer-container .reverie {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 426px) {
  .footer-container .reverie {
    margin-top: 4rem;
  }
}
.footer-container .grey-line {
  background-color: #a9afb8;
  width: 80%;
  height: 0.05rem;
  margin: 0 auto;
}
.footer-container .ending-tag {
  font-size: 14px;
  width: 80%;
  margin: 0 auto;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
